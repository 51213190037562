import React from "react"
import MainLayout from "../components/Layout/MainLayout"
import Logo from "../components/logo"
import classnames from "../helpers/classnames"
import Section from "../components/Section"
import Button from "../components/button"
import List from "../components/list"

const Page = ({ location }) => {

  return (
    <MainLayout>
      <div className={classnames("text-center my-32")}>
        <a href="/#" title="MINDWORX" className="logo">
          <Logo style={{ width: "90px", height: "90px" }}/>
        </a>
      </div>

      <Section
        title={<>This is great news, thank you!</>}
        className={classnames("m-auto pt-0")}
        style={{ maxWidth: "840px" }}
      >

        <p className={"mt-32"}>
          Some time ago you signed up to receive our emails but they probably all went to the spam folder. This is how
          we'll fix it in a few seconds:
        </p>

        <div className={"mt-64"}>
          <p>If you use <b>Gmail</b>:</p>
          <List className={classnames("my-16")} type="number">
            <>Log in to your Gmail account.</>
            <>Click the <b>gear</b> icon at the top right and then click <b>Settings</b>.</>
            <>Go to <b>filters and blocked addresses</b> and click <b>create a new filter</b>.</>
            <>In the <b>from</b> section, type the email address of the sender that you want to keep out of your Spam Folder. In this case, type ''mindworx'' in the HAS THE WORDS row.</>
            <>Click <b>create filter</b>.</>
            <>Check <b>never send it to spam</b> and <b>also apply filter to matching messages</b> boxes.</>
            <>Click <b>create filter</b> again. This way you prevent the emails from MINDWORX Academy from landing in your spam folder.</>
          </List>
        </div>

        <div className={"mt-64"}>
          <p>If you use <b>Apple Mail</b>:</p>
          <List className={classnames("my-16")} type="number">
            <>With the Apple Mail program open, click on <b>Mail > Preferences</b> of the left side of your Mac’s menu bar (right beside the Apple logo).</>
            <>Click on the <b>Junk Mail</b> tab.</>
            <>On <b>The following types of messages are exempt from junk mail filtering</b> section, make sure that <b>Sender of message is in my Contacts</b> is checked.</>
            <>To widen your whitelist scope, check <b>Sender of message is in my Previous Recipients</b> too.</>
            <>Done? You can now close out the Preferences window. All emails from anyone in your Contacts will now be whitelisted.</>
            <>Now search for an email from us in your junk mail. You can simply look up for ''MINDWORX Academy'' and then open any email from us.</>
            <>On the top of the email, highlight the name or email address with your mouse and click the upside-down arrow at its end.</>
            <>Select <b>Add to Contacts</b>.</>
            <>Done! This way you prevent the emails from MINDWORX Academy from landing in your junk folder.</>
          </List>
        </div>

        <p className={"mt-64 sm:mt-32"}>
          If you use a different email client let us know on <a className={"underlined underlined:hover"} href="mailto:info@mindworxacademy.com">info@mindworxacademy.com</a> so we can help you out.
        </p>

        <p className={"mt-64 sm:mt-32"}>Thank you and happy reading!</p>

      </Section>
    </MainLayout>
  )
}

export default Page
